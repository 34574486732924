import { useECharts } from '@kbox-labs/react-echarts'
import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { LineChartProps, loadingConfig } from 'home/constants/config'
import { generateConfig } from 'home/helpers/config'

export const useLineChart = ({
  data,
  series,
  loading,
  numberFormat,
}: LineChartProps) => {
  const [showTooltip, setShowTooltip] = useState(true)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const [lineChartRef, echartsInstance] = useECharts<HTMLDivElement>({})

  const config = useMemo(
    () =>
      generateConfig({
        data,
        series,
        showTooltip,
        tooltipRef,
        numberFormat,
      }),
    [data, series, showTooltip, tooltipRef, numberFormat]
  )

  useEffect(() => {
    if (echartsInstance && tooltipRef) {
      echartsInstance.setOption(loading ? loadingConfig : config, true)
    }
  }, [echartsInstance, tooltipRef, loading, config])

  const onCloseTooltip = (e: MouseEvent<HTMLDivElement>) => {
    const element = e.target as HTMLElement
    const isCloseTooltipButton =
      element.hasAttribute('data-action') &&
      element.getAttribute('data-action') === 'close-tooltip'

    if (isCloseTooltipButton) {
      setShowTooltip(false)
    }
  }

  useEffect(() => {
    const zRender = echartsInstance?.getZr()
    if (!zRender) {
      return undefined
    }

    zRender.on('mousedown', () => setShowTooltip(true))
    return () => zRender.off('mousedown')
  }, [echartsInstance])

  return {
    echart: {
      lineChartRef,
      echartsInstance,
    },
    tooltip: {
      tooltipRef,
      setTooltipRef,
      showTooltip,
      setShowTooltip,
      onCloseTooltip,
    },
  }
}
